import Container from "../layout/Container";


const NotFoundUser = () => {
  return (
    <section className="not-found fade-in">
      <Container>
        <h1>Not Found User</h1>
        <p>The page you are looking for does not exist...</p>
      </Container>
    </section>
  );
};

export default NotFoundUser;
