import React from "react";
import { Link } from "react-router-dom";
import {
  CameraIcon,
  DeleteIcon,
  QrCodeIcon,
  RestoreIcon,
  ShareIcon,
  TrashIcon,
  HelpIcon,
} from "../helpers/svg";

const Button = ({
  children,
  link,
  variant,
  type,
  onClick,
  reload,
  download,
  disabled,
}) => {
  return link ? (
    <Link
      className={`btn ${variant ? `btn--${variant}` : ""} `}
      to={link}
      role="button"
      onClick={onClick}
      reloadDocument={reload && true}
      download={download && download}
      disabled={disabled && disabled}
    >
      {children}
    </Link>
  ) : (
    <button
      className={`btn ${variant ? `btn--${variant}` : ""}`}
      type={type}
      onClick={onClick}
      disabled={disabled && disabled}
    >
      {children}
    </button>
  );
};

export default Button;

export const IButton = ({ name, title, onClick, type }) =>
  name === "remove" ? (
    <button
      className="btn btn--primary icon"
      aria-label={title ? title : name}
      onClick={onClick}
      type={type && type}
    >
      <TrashIcon />
    </button>
  ) : name === "select img" ? (
    <button
      className="btn btn--blue icon"
      aria-label={title ? title : name}
      onClick={onClick}
      type={type && type}
    >
      <CameraIcon />
    </button>
  ) : name === "delete" ? (
    <button
      className="btn btn--delete icon"
      aria-label={title ? title : name}
      onClick={onClick}
      type={type && type}
    >
      <DeleteIcon />
    </button>
  ) : name === "qr code" ? (
    <button
      className="btn btn--secondary icon"
      aria-label={title ? title : name}
      onClick={onClick}
      type={type && type}
    >
      <QrCodeIcon />
    </button>
  ) : name === "share" ? (
    <button
      className="btn btn--secondary icon"
      aria-label={title ? title : name}
      onClick={onClick}
      type={type && type}
    >
      <ShareIcon />
    </button>
  ) : name === "restore" ? (
    <button
      className="btn btn--primary icon"
      aria-label={title ? title : name}
      onClick={onClick}
      type={type && type}
    >
      <RestoreIcon />
    </button>
  ) : name === "help" ? (
    <button
      className="btn btn--primary icon"
      aria-label={title ? title : name}
      onClick={onClick}
      type={type && type}
    >
      <HelpIcon />
    </button>
  ) : null;
