import { useEffect } from "react";
import Create from "../components/CreateAccount";
import { useLocation } from "react-router-dom";
import Container from "../layout/Container";

const CreateAccount = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <section className="home">
      <Container>
        <div className="home__signs">
          <Create />
        </div>
      </Container>
    </section>
  );
};

export default CreateAccount;
