import { useState } from "react";

const BioBox = ({ text }) => {
  const [seeMore, setSeeMore] = useState(false);
  const screen =
    window.innerWidth >= 992 ? 144 : window.innerWidth >= 540 ? 96 : 72;
  return (
    <div className="box">
      <p className={`${seeMore ? "" : "text"}`}>
        {text.length > screen
          ? seeMore
            ? text
            : text.substring(0, screen) + "..."
          : text}
      </p>
      {text.length > screen && (
        <button className="see-more-btn" onClick={() => setSeeMore(!seeMore)}>
          {seeMore ? "see less..." : "see more..."}
        </button>
      )}
    </div>
  );
};

export default BioBox;
