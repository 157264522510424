import React from "react";

const SwitchButton = ({ label, name, onChange, value }) => {
  return (
    <label className="switch">
      <input
        name={name}
        type="checkbox"
        checked={value}
        aria-label={label ? label : name}
        onChange={onChange}
      />
      <span className="slider"></span>
    </label>
  );
};

export default SwitchButton;
