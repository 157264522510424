import { useEffect } from "react";
import { BackIcon, CheckIcon, LogoIcon } from "../helpers/svg";
import Button from "./Button";

const PasswordIsSet = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "auto");
  }, []);

  return (
    <section className="password-set">
      <div className="password-set__content">
        <div className="password-set__icon">
          <CheckIcon />
        </div>
        <h1>Success</h1>
        <p>
          Password reset successfully, Now you can log in with your new
          password!
        </p>
        <Button variant="primary" link="/login">
          <BackIcon />
          <span>Back to log in</span>
        </Button>
      </div>
      <footer className="email-sent__footer">
        <small>
          vCard powered by
          <a className="email-sent__footer-logo" href="https://nfc.com.eg/">
            <LogoIcon />
          </a>
        </small>
      </footer>
    </section>
  );
};

export default PasswordIsSet;
