import React from "react";
import { DeleteIcon } from "../helpers/svg";

const ErrorMsg = ({ msg, onClick }) => {
  return (
    <div className="error-msg-log">
      <div className="text">{msg}</div>
        <button onClick={onClick}>
          <DeleteIcon />
        </button>
    </div>
  );
};

export default ErrorMsg;
