import { useState, useEffect, useContext } from "react";
import Container from "../layout/Container";
import { AuthedUserContext } from "../store/authedUser";
import Field from "../layout/forms/Field";
import Button from "../components/Button";
import Cookies from "js-cookie";
import { BeatLoader } from "react-spinners";
import ErrorMsg from "../components/ErrorMsg";
import { Navigate } from "react-router-dom";

const Settings = () => {
  const authToken = Cookies.get("auth_token");
  const { authedUser } = useContext(AuthedUserContext);
  const { configs, setConfigs } = useContext(AuthedUserContext);

  const [errorLog, setErrorLog] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => setErrorLog(""), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [errorLog]);
  const closeErrorLog = (_) => setErrorLog("");

  const [state, setState] = useState({
    status: "idle",
    errors: false,
    errorMsg: {},
    values: {
      tutorialLink: "",
    },
  });
  const { tutorialLink } = state.values;
  const { status, errorMsg } = state;

  useEffect(() => {
    setState((currState) => ({
      ...currState,
      values: configs,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs]);

  useEffect(() => {}, [errorMsg]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValue(name, value);
  };

  const setValue = (name, value) => {
    setState((currForm) => ({
      ...currForm,
      values: {
        ...currForm.values,
        [name]: value,
      },
    }));
  };

  const setErrorMsg = (name, msg) =>
    setState((currState) => ({
      ...currState,
      errorMsg: {
        ...currState.errorMsg,
        [name]: msg,
      },
    }));

  const setStatus = (stu) =>
    setState((currState) => ({
      ...currState,
      status: stu,
    }));

  // Form data validation
  const validation = () => {
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("loading");

    if (validation()) {
      setErrorMsg({});
      sendData();
    } else {
      setStatus("errors");
    }
  };

  const sendData = () => {
    setErrorLog("");
    // post values to the server
    fetch(`${process.env.REACT_APP_S}/api/configs/${configs._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: authToken,
      },
      body: JSON.stringify({
        tutorialLink,
      }),
    })
      .then(async (res) => ({
        res,
        data: await res.json(),
      }))
      .then(({ res, data }) => {
        console.log("data", data);
        if (res.status === 200) {
          setStatus("success");
          setConfigs(data);
        } else if (data.error) {
          setStatus("errors");
          setErrorLog(data.error);
          setErrorMsg("oldPassword", "Old password is incorrect");
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setStatus("errors");
        setErrorLog("Error form the server, try again later");
      });
  };

  return !authedUser ? (
    <Navigate to="/" />
  ) : (
    <section className="settings">
      {errorLog && <ErrorMsg msg={errorLog} onClick={closeErrorLog} />}
      <Container>
        <h1>Configs</h1>
        <div className="settings__account">
          <div className="settings__change-password">
            <form className="settings__form" onSubmit={handleSubmit}>
              <Field
                name="tutorial link"
                placeholder="Tutorial link"
                onChange={handleChange}
                value={tutorialLink}
                errorMsg={errorMsg}
              />
              <Button
                variant="primary"
                type="submit"
                disabled={status === "loading" ? true : false}
              >
                {status === "loading" ? <BeatLoader color="#ffffff" /> : "Save"}
              </Button>
            </form>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Settings;
