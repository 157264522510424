import { useState, useEffect } from "react";
import { checkValidCPassword, checkValidPassword } from "../helpers/validation";
import ErrorMsg from "../components/ErrorMsg";
import Field from "../layout/forms/Field";
import Button from "../components/Button";
import { BeatLoader } from "react-spinners";
import Container from "../layout/Container";
import { BackIcon, ResetPasswordIcon } from "../helpers/svg";
import { Link, useParams } from "react-router-dom";
import PasswordIsSet from "../components/PasswordIsSet";

const ResetPassword = () => {
  const { token } = useParams();
  const [errorLog, setErrorLog] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => setErrorLog(""), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [errorLog]);
  const closeErrorLog = (_) => setErrorLog("");

  //
  const [isPasswordSet, setIsPasswordSet] = useState(false);

  const [state, setState] = useState({
    status: "idle",
    errors: false,
    errorMsg: {},
    values: {
      password: "",
      confirmPassword: "",
    },
  });
  const { password, confirmPassword } = state.values;
  const { status, errorMsg } = state;

  useEffect(() => {
    errorMsg && (errorMsg.password || errorMsg.confirmPassword)
      ? setState((currState) => ({
          ...currState,
          errors: true,
        }))
      : setState((currState) => ({
          ...currState,
          errors: false,
        }));
  }, [errorMsg]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValue(name, value);
    // Check input if there is an error
    status === "errors" && inputChecker(name, value);
  };

  const setValue = (name, value) => {
    setState((currForm) => ({
      ...currForm,
      values: {
        ...currForm.values,
        [name]: value,
      },
    }));
  };

  const inputChecker = (name, value) => {
    if (name === "password") {
      setErrorMsg("password", checkValidPassword(value));
    }
    if (name === "confirmPassword") {
      setErrorMsg("confirmPassword", checkValidCPassword(password, value));
    }
  };

  const setErrorMsg = (name, msg) =>
    setState((currState) => ({
      ...currState,
      errorMsg: {
        ...currState.errorMsg,
        [name]: msg,
      },
    }));

  const setStatus = (stu) =>
    setState((currState) => ({
      ...currState,
      status: stu,
    }));

  // Form data validation
  const validation = () => {
    state.errorMsg = {
      password: checkValidPassword(password),
      confirmPassword: checkValidCPassword(password, confirmPassword),
    };
    return checkErrors(state.errorMsg) ? false : true;
  };

  const checkErrors = (errorMsg) => {
    let errors = false;
    Object.values(errorMsg).forEach((value) => {
      if (value) errors = true;
    });
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("loading");

    if (validation()) {
      setErrorMsg({});
      sendData();
    } else {
      setStatus("errors");
    }
  };

  const sendData = () => {
    // post values to the server
    fetch(`${process.env.REACT_APP_S}/api/reset-password/${token}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
      }),
    })
      .then(async (res) => ({
        res,
        data: await res.json(),
      }))
      .then(({ res, data }) => {
        if (res.status === 200) {
          setStatus("success");
          setIsPasswordSet(true);
        } else if (data.error) {
          setStatus("errors");
          setErrorLog(data.error);
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setStatus("errors");
        setErrorLog("Error form the server, try again later");
      });
  };
  return (
    <section className="reset-password">
      {errorLog && <ErrorMsg msg={errorLog} onClick={closeErrorLog} />}
      <Container>
        <ResetPasswordIcon />
        <h1 className="reset-password__title">Reset your password</h1>
        <p className="reset-password__paragraph">
          Almost done, just enter your new password below must be at least 8
          characters!
        </p>
        <form className="reset-password__form" onSubmit={handleSubmit}>
          <Field
            name="password"
            placeholder="new password"
            onChange={handleChange}
            value={password}
            errorMsg={errorMsg}
          />
          <Field
            name="confirm password"
            placeholder="confirm new password"
            onChange={handleChange}
            value={confirmPassword}
            errorMsg={errorMsg}
          />
          <Button
            variant="primary"
            type="submit"
            disabled={status === "loading" ? true : false}
          >
            {status === "loading" ? (
              <BeatLoader color="#ffffff" />
            ) : (
              "Reset Password"
            )}
          </Button>
          <Link className="reset-password__back" to="/">
            <BackIcon />
            <span>Back to home</span>
          </Link>
        </form>
      </Container>
      {isPasswordSet && <PasswordIsSet />}
    </section>
  );
};

export default ResetPassword;
