const Select = ({ name, options, onChange, value, id }) => (
  <div className="select">
    <select id={id && id} name={name} onChange={onChange} value={value}>
      {options.map((option, index) => (
        <option
          key={index}
          value={option.value.toUpperCase()}
          defaultValue={value === option.value ? true : false}
        >
          {option.option ? option.option : option.value}
        </option>
      ))}
    </select>
  </div>
);

export default Select;
