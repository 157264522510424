import React from "react";
import Input from "./Input";

const ContactNameFields = ({ handleChange, values, errorMsg }) => {
  const contactName = values;
  return (
    <fieldset
      className={`contact-name ${
        errorMsg && errorMsg.contactName ? "error" : ""
      }`}
    >
      <div className="fieldset-container">
        <legend>Contact name</legend>
        <div className="inputs">
          <div>
            <Input
              label
              name="title"
              fieldset="contactName"
              onChange={handleChange}
              value={contactName.title}
            />
            <Input
              label
              name="suffix"
              fieldset="contactName"
              onChange={handleChange}
              value={contactName.suffix}
            />
          </div>
          <div>
            <Input
              label
              name="first name"
              fieldset="contactName"
              onChange={handleChange}
              value={contactName.firstName}
            />
            <Input
              label
              name="last name"
              fieldset="contactName"
              onChange={handleChange}
              value={contactName.lastName}
            />
          </div>
          {errorMsg && errorMsg.contactName && (
            <span className="error-msg">{errorMsg.contactName}</span>
          )}
        </div>
      </div>
    </fieldset>
  );
};

export default ContactNameFields;
