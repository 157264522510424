const uid = () => {
  const chars =
      "1234567890zxcvbnmasdfghjklqwertyuiopZXCVBNMASDFGHJKLQWERTYUIOP",
    charsCount = 99;
  let uid = "";
  for (let i = 0; i < charsCount; i++)
    uid += chars[Math.floor(Math.random() * chars.length)];

  return uid;
};

export default uid;
