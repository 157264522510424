import { useContext, useEffect, useState } from "react";
import { AuthedUserContext } from "./store/authedUser";
import Layout from "./layout";
import { Outlet } from "react-router-dom";
import Cookies from "js-cookie";

const App = () => {
  const [status, setStatus] = useState("");
  const { setAuthedUser, setConfigs } = useContext(AuthedUserContext);

  useEffect(() => {
    getConfigs();
    const authToken = Cookies.get("auth_token");
    if (authToken) {
      setStatus("loading");
      getAuthData(authToken);
    } else {
      setStatus("success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getConfigs = (token) => {
    fetch(`${process.env.REACT_APP_S}/api/configs`)
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        setConfigs(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getAuthData = (token) => {
    fetch(`${process.env.REACT_APP_S}/api/authed-user`, {
      headers: { authorization: token },
    })
      .then((res) => {
        if (res.status === 401) {
          Cookies.remove("auth_token");
          setAuthedUser(false);
          setStatus("success");
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setAuthedUser(data);
        setStatus("success");
      })
      .catch((err) => {
        console.log("err", err);
        setStatus("failed");
      });
  };
  return (
    (status === "success" || status === "failed") && (
      <Layout>
        <Outlet />
      </Layout>
    )
  );
};

export default App;
