import { useState, useEffect } from "react";
import Field from "../layout/forms/Field";
import Button from "./Button";
import { BeatLoader } from "react-spinners";
import { checkValidInput } from "../helpers/validation";
import ErrorMsg from "./ErrorMsg";
import { BackIcon } from "../helpers/svg";
import { Link } from "react-router-dom";
import EmailSent from "./EmailSent";
const ForgetPassword = () => {
  const [state, setState] = useState({
    status: "idle",
    errorMsg: {},
    errors: false,
    values: {
      email: "",
    },
  });
  const { email } = state.values;
  const { status, errorMsg } = state;

  //
  const [isEmailSent, setIsEmailSent] = useState(false);
  //
  const [errorLog, setErrorLog] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => setErrorLog(""), 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [errorLog]);
  const closeErrorLog = (_) => setErrorLog("");

  useEffect(() => {
    errorMsg && (errorMsg.email || errorMsg.password)
      ? setState((currState) => ({
          ...currState,
          errors: true,
        }))
      : setState((currState) => ({
          ...currState,
          errors: false,
        }));
  }, [errorMsg]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValue(name, value);

    // Check input if there is an error
    status === "errors" && inputChecker(name, value);
  };

  const setValue = (name, value) => {
    setState((currForm) => ({
      ...currForm,
      values: {
        ...currForm.values,
        [name]: value,
      },
    }));
  };

  const inputChecker = (name, value) => {
    if (name === "email") {
      setErrorMsg("email", checkValidInput(value, "account"));
    }
  };

  const setErrorMsg = (name, msg) =>
    setState((currState) => ({
      ...currState,
      errorMsg: {
        ...currState.errorMsg,
        [name]: msg,
      },
    }));

  const setStatus = (stu) =>
    setState((currState) => ({
      ...currState,
      status: stu,
    }));

  // Form data validation
  const validation = () => {
    state.errorMsg = {
      email: checkValidInput(email, "account"),
    };
    return checkErrors(state.errorMsg) ? false : true;
  };

  const checkErrors = (errMsg) => {
    let errors = false;
    Object.values(errMsg).forEach((value) => {
      if (value) errors = true;
    });
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("loading");
    if (validation()) {
      setErrorMsg({});
      sendData();
    } else {
      setStatus("errors");
    }
  };

  const sendData = () => {
    fetch(`${process.env.REACT_APP_S}/api/forgot-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then((res) => {
        if (res.status === 404) {
          setState((currState) => ({
            ...currState,
            status: "errors",
            errorMsg: {
              ...currState.errorMsg,
              email: "The email is incorrect",
            },
          }));
          setErrorLog("The email is incorrect");
        } else {
          setStatus("success");
          setIsEmailSent(true);
        }
        return res.json();
      })
      // .then((data) => console.log("forget password data: ", data))
      .catch((err) => {
        console.log(err, "err");
        setStatus("errors");
        setErrorLog("Error form the server, try again later");
      });
  };

  return (
    <section className="home__forget-password fade-in">
      {errorLog && <ErrorMsg msg={errorLog} onClick={closeErrorLog} />}
      <div className="home__forget-password-heading">
        <Link to="/login" aria-label="back">
          <BackIcon />
        </Link>
        <h1>Forget Password?</h1>
      </div>
      <p>We'll send an email with instructions to reset your password.</p>
      <form className="home__forget-password-form" onSubmit={handleSubmit}>
        <Field
          name="email"
          onChange={handleChange}
          value={email}
          errorMsg={errorMsg}
        />
        <Button
          variant="primary"
          type="submit"
          disabled={status === "loading" ? true : false}
        >
          {status === "loading" ? (
            <BeatLoader color="#ffffff" />
          ) : (
            "Send instructions"
          )}
        </Button>
      </form>
      {isEmailSent && <EmailSent />}
    </section>
  );
};

export default ForgetPassword;
