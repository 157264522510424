import { useState } from "react";
import { toKebabCase, toCamelCase } from "../../helpers/functions.js";
import Input from "./Input.js";
import { EyeIcon, EyeSlashIcon } from "../../helpers/svg/index.js";

const Field = ({ name, onChange, value, label, placeholder, errorMsg }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  return (
    <div
      className={`field ${
        errorMsg && errorMsg[toCamelCase(name)] ? "error" : ""
      }`}
    >
      <label htmlFor={toKebabCase(name)}>{label ? label : name}</label>
      <div>
        <Input
          name={name}
          onChange={onChange}
          value={value}
          type={isPasswordVisible && "text"}
          placeholder={placeholder && placeholder}
        />
        {name.includes("password") && (
          <span
            className={`show-password ${isPasswordVisible ? "hide" : ""}`}
            onClick={() => setIsPasswordVisible((prev) => !prev)}
          >
            {isPasswordVisible ? <EyeSlashIcon /> : <EyeIcon />}
          </span>
        )}
      </div>
      {errorMsg && errorMsg[toCamelCase(name)] && (
        <span className="error-msg">{errorMsg[toCamelCase(name)]}</span>
      )}
    </div>
  );
};

export default Field;
