import SwitchButton from "../../components/SwitchButton";
import TextArea from "./TextArea";

const TextAreaField = ({ handleChange, values }) => {
  const note = values;
  return (
    <fieldset className="note">
      <div className="fieldset-container">
        <legend>Note</legend>
        <div className="inputs">
          <TextArea
            label="note"
            name="text"
            fieldset="note"
            placeholder="note"
            onChange={handleChange}
            value={note.text}
          />
          <SwitchButton
            label="my note"
            name="note.public"
            onChange={handleChange}
            value={note.public}
          />
        </div>
      </div>
    </fieldset>
  );
};

export default TextAreaField;
