import { toKebabCase, toCamelCase } from "../../helpers/functions.js";

const TextArea = ({ name, onChange, value, fieldset, placeholder, label }) => (
  <textarea
    id={!label ? toKebabCase(name) : undefined}
    name={fieldset ? `${fieldset}.${toCamelCase(name)}` : toCamelCase(name)}
    placeholder={placeholder ? placeholder : name}
    aria-label={label && label.length >= 1 ? label : name}
    rows="5"
    onChange={onChange}
    value={value}
  />
);

export default TextArea;
