import { useEffect, useState, useContext } from "react";
import Container from "../layout/Container";
import Loading from "../components/Loading";
import { StatisticsIcon } from "../helpers/svg";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { AuthedUserContext } from "../store/authedUser";
import Button from "../components/Button";
import {
  DisableIcon,
  AdminIcon,
  EditIcon,
  EyeIcon,
  TrashIcon,
} from "../helpers/svg";

const Statistics = () => {
  const { authedUser } = useContext(AuthedUserContext);
  const [statistics, setStatistics] = useState(false);
  const authToken = Cookies.get("auth_token");
  useEffect(() => {
    authedUser ? getData() : setStatistics({ message: "Unauthorized" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    fetch(`${process.env.REACT_APP_S}/api/statistics`, {
      headers: { authorization: authToken },
    })
      .then((data) => data.json())
      .then((data) => {
        setStatistics(data);
      });
  };

  const openInNewTab = function (path) {
    window.open(path);
  };
  const removeContact = function (id) {
    fetch(`${process.env.REACT_APP_S}/api/contact/${id}`, {
      method: "DELETE",
      headers: { authorization: authToken },
    })
      .then((data) => data.json())
      .then((data) => {
        getData();
      });
  };
  const disableContact = function (id) {
    fetch(`${process.env.REACT_APP_S}/api/contact/${id}/disable`, {
      method: "PATCH",
      headers: { authorization: authToken },
    })
      .then((data) => data.json())
      .then((data) => {
        getData();
      });
  };
  const adminContact = function (id) {
    fetch(`${process.env.REACT_APP_S}/api/contact/${id}/admin`, {
      method: "PATCH",
      headers: { authorization: authToken },
    })
      .then((data) => data.json())
      .then((data) => {
        getData();
      });
  };

  return !statistics ? (
    <Loading />
  ) : statistics.message ? (
    <Navigate to="/" />
  ) : (
    <section className="statistics">
      <Container>
        <h1>
          <StatisticsIcon /> Statistics
        </h1>
        <h2>Users: {statistics.users.count}</h2>
        <h2>Contacts: {statistics.contacts.count}</h2>
        <h2>
          Profile Completed:{" "}
          {Math.round(
            (statistics.contacts.count * 100) / statistics.users.count
          )}
          %
        </h2>
        {authedUser.isAdmin && (
          <div className="dashboard">
            <Button variant="primary" link="/create-account" className="fit">
              Create new account
            </Button>
          </div>
        )}
        <div className="statistics__contacts">
          <ul>
            {statistics.contacts.contacts_array.map((contact, index) => (
              <li key={index}>
                <div className="item">
                  <div className="name">
                    {index + 1}. {contact.name.title} {contact.name.firstName}{" "}
                    {contact.name.lastName} {contact.name.suffix}
                  </div>
                  <div className="action">
                    {/* <a
                      href={`${process.env.REACT_APP_S}/card/${contact.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                    </a> */}
                  </div>
                  <div className="action">
                    <button onClick={() => openInNewTab(`/card/${contact.id}`)}>
                      <EyeIcon />
                    </button>
                  </div>
                  <div className="action">
                    <button
                      onClick={() =>
                        openInNewTab(`/admin/card/${contact.id}/edit`)
                      }
                    >
                      <EditIcon />
                    </button>
                  </div>
                  <div className="action">
                    <button onClick={() => removeContact(contact.id)}>
                      <TrashIcon />
                    </button>
                  </div>
                  <div className="action">
                    <button onClick={() => disableContact(contact.id)} style={{opacity: contact.disabled?1:.3}}>
                      <DisableIcon />
                    </button>
                  </div>
                  {authedUser.isSuperAdmin && (
                    <div className="action">
                      <button onClick={() => adminContact(contact.id)} style={{opacity: contact.isAdmin?1:.3}}>
                         <AdminIcon />
                      </button>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </section>
  );
};

export default Statistics;
