import { useContext } from "react";
import { AuthedUserContext } from "../store/authedUser";
import Container from "../layout/Container";
import CardForm from "../layout/forms/AdminCardForm";
import { Navigate, useLocation } from "react-router-dom";
import Uploader from "../components/updoader";

const EditCard = () => {
  const { authedUser } = useContext(AuthedUserContext);
  const { pathname } = useLocation();

  const notAuthorized =
    !authedUser ||
    (!(authedUser.isSuperAdmin || authedUser.isAdmin) && !pathname.includes(authedUser.id));

  return notAuthorized ? (
    <Navigate to="/" />
  ) : !authedUser.contact ? (
    <Navigate to="/create-vcard" />
  ) : (
    <section className="edit-card fade-in">
      <Container>
        <CardForm handle="edit_card" />
        <Uploader contact="authedUser" />
      </Container>
    </section>
  );
};

export default EditCard;
