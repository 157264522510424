/**
 * Kebab Case
 * Function to replace all spaces to dash sign
 * @param {string} str
 * @returns {string}
 * @author NFC Egypt <www.nfc.eg>
 */
export const toKebabCase = (str) => str.replaceAll(" ", "-");

/**
 * Camel Case
 * @param {string} str
 * @returns {string}
 * @author NFC Egypt <www.nfc.eg>
 */
export const toCamelCase = (str) =>
  str.replace(/\s(.)/g, (next) => next.toUpperCase()).replace(/\s/g, "");

export const toPascalCase = (str) =>
  str.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
/**
 * Catch number from string
 * @param {string} str
 * @returns {string}
 */
export const catchNumber = (str) => {
  const isMatched = str.match(/\d+/);
  return isMatched !== null && isMatched[0];
};

/**
 * Convert a date string in the format "dd/mm/yyyy" to a date object
 * @param {string} dateStr
 * @returns
 */
export const formatDate = (dateStr) =>
  new Date(dateStr).toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

/**
 * Function to check if a URL points to an image or not.
 * @param {string} url
 * @returns {boolean}
 */
export const isImageUrl = (url) =>
  new Promise((resolve, reject) => {
    let image = new Image();
    image.src = url;
    image.onload = function () {
      resolve(true);
    };
    image.onerror = function () {
      resolve(false);
    };
  });

/**
 * Function to convert username to URL
 * @param {string} type
 * @param {string} link
 * @returns {string} the link
 * @author NFC Egypt <www.nfc.eg>
 */
export const usernameToURL = (type, link) => {
  type = type.toLowerCase();
  if (type === "facebook") {
    !link.includes("facebook.com") && (link = `https://facebook.com/${link}`);
  }
  if (type === "instagram") {
    !link.includes("instagram.com") && (link = `https://instagram.com/${link}`);
    link.includes("@") && (link = link.replace("@", ""));
  }
  if (type === "whatsapp" || type === "wa business") {
    link = `https://wa.me/${link}`;
  }
  if (type === "twitter") {
    !link.includes("twitter.com") && (link = `https://twitter.com/${link}`);
    link.includes("@") && (link = link.replace("@", ""));
  }
  if (type === "youtube") {
    !link.includes("youtube.com") && (link = `https://youtube.com/${link}`);
  }
  if (type === "tiktok") {
    !link.includes("tiktok.com") && (link = `https://tiktok.com/${link}`);
    !link.includes("@") && (link = link.replace(".com/", ".com/@"));
  }
  if (type === "linkedin") {
    !link.includes("linkedin.com") &&
      (link = `https://linkedin.com/in/${link}`);
  }
  if (type === "telegram") {
    !link.includes("t.me") && (link = `https://t.me/${link}`);
    link.includes("@") && (link = link.replace("@", ""));
  }
  if (type === "snapchat") {
    !link.includes("snapchat.com") &&
      (link = `https://snapchat.com/add/${link}`);
    link.includes("@") && (link = link.replace("@", ""));
  }
  if (link && !link.includes("http")) {
    link = `https://${link}`;
  }
  return link;
};

/**
 * Function to trim URL
 * @param {string} url
 * @returns {string} URL
 */
export const trimURL = (url) => {
  if (url.includes("http://")) {
    url = url.replace("http://", "");
  }
  if (url.includes("https://")) {
    url = url.replace("https://", "");
  }
  if (url.includes("www.")) {
    url = url.replace("www.", "");
  }
  if (url.includes("wa.me/")) {
    url = url.replace("wa.me/", "");
  }
  if (url.includes("wa.me/")) {
    url = url.replace("wa.me/", "");
  }
  if (url[url.length - 1] === "/") {
    url = url.slice(0, -1);
  }
  return url;
};
