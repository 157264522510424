import { useContext } from "react";
import Container from "../layout/Container";
import { AuthedUserContext } from "../store/authedUser";
import { Navigate } from "react-router-dom";
import CardForm from "../layout/forms/CardForm";

const CreateVCard = () => {
  const { authedUser } = useContext(AuthedUserContext);
  return authedUser && !authedUser.contact ? (
    <section className="create-card fade-in">
      <Container>
        <div className="create-card__heading">
          <h1>Create your card</h1>
          <p>
            Complete the form below with your contact information, Then copy
            your card link or QR-code and share it.
          </p>
        </div>

        <CardForm handle="create_contact" />
      </Container>
    </section>
  ) : (
    <Navigate to="/" />
  );
};

export default CreateVCard;
