/**
 * Name Validation
 * Function to check if the name is valid
 * @param {string} fname - first name
 * @param {string} lname - last name
 * @returns {string}
 * @author NFC Egypt <www.nfc.eg>
 */
export const checkValidName = (fname, lname) =>
  !fname && lname
    ? "Provide your first name"
    : !fname && !lname
    ? "You must provide at least a first name"
    : !/[a-zA-Z]/.test(fname)
    ? "Please provide a valid name"
    : "";

/**
 * Function to check if the input is valid
 * @param {*} input
 * @param {string} name - name the input
 * @returns {string}
 * @author NFC Egypt <www.nfc.eg>
 */
export const checkValidInput = (input, name) =>
  !input ? `Provide your ${name}` : "";

/**
 * Email Validation
 * Function to check if the email is valid
 * @param {string} email
 * @returns {string}
 * @author NFC Egypt <www.nfc.eg>
 */
export const checkValidEmail = (email) =>
  !email
    ? "This field is empty"
    : !isValidEmail(email)
    ? "Please provide a valid email address"
    : "";
// is the email address valid
const isValidEmail = (email) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

/**
 * Password Validation
 * Function to check if the password is valid
 * @param {number} password 
 * @returns {string}
 * @author NFC Egypt <www.nfc.eg>
 */
export const checkValidPassword = (password) =>
  !password.length
    ? "This field is empty"
    : password.length < 8
    ? "Password must be at least 8 characters"
    : "";

/**
 * Function to check if the confirm password is valid
 * @param {number} password
 * @param {number} confirmPassword 
 * @returns {string} 
 * @author NFC Egypt <www.nfc.eg>
 */
export const checkValidCPassword = (password, confirmPassword) =>
  confirmPassword && password !== confirmPassword
    ? "Confirm password is not matched!"
    : !confirmPassword
    ? "Confirm password is required"
    : "";

/**
 * Date Validation
 * Function to check if the date is valid
 * @param {*} day 
 * @param {*} month 
 * @param {*} year 
 * @returns  {string}
 * @author NFC Egypt <www.nfc.eg>
 */
export const checkValidDate = (day, month, year) => {
  return !month && !day && !year
    ? ""
    : ((month || day) && !year) ||
      ((month || year) && !day) ||
      ((year || day) && !month)
    ? "Date is incomplete"
    : (+month === 2 && +day > 29) ||
      (+month === 2 && +day === 29 && !Number.isInteger(+year / 4)) ||
      (+month === 4 && +day === 31) ||
      (+month === 6 && +day === 31) ||
      (+month === 9 && +day === 31) ||
      (+month === 11 && +day === 31) ||
      isNaN(new Date(`${year}-${month}-${day}`).getTime())
    ? "Invalid date"
    : "";
};
