import { useState, useEffect } from "react";
import { DeleteIcon, DownloadIcon } from "../helpers/svg";
import QRCode from "qrcode";

const QrCode = ({ isOpen, setIsOpen }) => {
  const url = window.location.href;
  const [qr, setQr] = useState("");

  useEffect(() => {
    GenerateQRCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GenerateQRCode = () => {
    QRCode.toDataURL(
      url,
      {
        width: 500,
        margin: 2,
        color: {
          dark: "#1e1e1e",
          // light: "#EEEEEEFF",
        },
      },
      (err, url) => {
        if (err) return console.error(err);
        setQr(url);
      }
    );
  };



  return (
    <div className="qrcode">
      <div className={`qrcode__scan ${isOpen ? "open" : ""}`}>
        <div className="qrcode__scan-wrapper">
          <div className="qrcode__scan-heading">
            <h2>Scan QR Code</h2>
            <button
              className="qrcode__scan-close-btn"
              aria-label="close scan qr code"
              onClick={() => setIsOpen(false)}
            >
              <DeleteIcon />
            </button>
          </div>
          <picture>
            <img src={qr} alt="qr code" />
          </picture>
          <div className="qrcode__scan-download-btn">
            <a
              className="btn btn--primary"
              role="button"
              href={qr}
              download="qrcode.png"
            >
              <DownloadIcon /> Download
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrCode;
