import Checkbox from "./Checkbox";

const RememberMe = ({value, onChange}) => {
  return (
    <div className="home__login-remember">
      <Checkbox
        name="remember me"
        value={value}
        onChange={onChange}
      />
      <div className="text">Remember me</div>
    </div>
  );
};

export default RememberMe;
